@import "~bootstrap/scss/bootstrap.scss";


.login {
  background-color: #15b5a2;
  height: 100vh;

  .login-card {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .login-container-form {
    flex-direction: column;
    background-color: #ffff;
    border-radius: 16px 0 0 16px;
    height: 86vh;
    display: flex;
    justify-content: center;
  }


  .login-container-image {
    background: rgba(20, 149, 54, 1) no-repeat;
    color: #ffff;
    border-radius: 0 16px 16px 0;
    background-image: url(./assets/icon-credicard.png);
    background-size: 421px;
    background-position-x: right;
    background-position-y: bottom;
    height: 86vh;

    .container {
      h2{
        font-size: 50px;

      }
      p{
        font-size: 17px;
      }
      padding: 14% 10px 0 29px;
    }
  }
}

// View Payments //
.hr{
  width: 80%;
  margin-bottom: 30px;
}
.total-amount{
  color:#474646;
  font-size: 15px;
  padding-left: 14px;
  font-weight: bold;
}

.makePayment{
  display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 100px;

    .btn-primary {
      color: #fff;
      background-color: #0f385a;
      border-color: #0f385a;
      padding: 7px 25px;
      font-size: 15px;
    }

    .btn-primary:hover{
      background-color:#4a9441;
      border-color: #4a9441;
    }

    .btn-primary:focus{
      background-color:#4a9441;
      border-color: #4a9441;
    }

    span{
    font-size: 24px;
    color: #10395a;
    }
}

.container-text{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .form-check-label {
    margin-bottom: 0;
    font-weight: 500;
    color: #474646;
    font-size: 16px;
  }
}




// pag style 404 //
.container-404{
  padding: 140px 20px;
  .text{
      text-align: center;
      padding: 52px 47px 0px;
    h3{
      position: relative;
      top: 23px;
      color: #474646;
    }
    h1{
        font-size: 100px;
        font-weight: 700;
        color: #0C365C;
    }
    p{
      font-size: 13px;
      color: #474646;
      position: relative;
      bottom: 16px;
    }

    Button{
    color: #0C365C;
    border-color: #0C365C;
    }
    Button:hover{
      background: #0C365C;
      color:#fff;
    }
  }


  .container-vector{
    text-align: center;
    img{
      width: 300px;
      height: 400px;
    }
  }
}


// media only screen for desktop for page 404
@media only screen and (min-width: 992px) {

  .container-404{
      h1{
          font-size: 150px !important;
      }
    }

  .container-vector{
    text-align: center;
    img{
      width: 450px !important;
      height: 400px !important;
    }
  }
}


// media only screen for movil and tablet for page 404
@media only screen and (max-width: 992px) {
  .container-404{
    padding: 0px 20px;
}

}

@media only screen and (max-width: 800px) {
  .login{
    height: 0;
    .login-card{
      padding-top: 0;
      padding-bottom: 0;
    }
    .login-container-image {
      border-radius: 16px 16px 16px 16px;
    }
    .login-form{
      padding: 0;
      margin: none;
      padding-left: 0 !important;
    }
    .login-container-form{
      border-radius: 0 0 0 0;
    }
    .pr-0{
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }
  .payment-process{
    padding-top: 0 !important;
  }
}





// responsive screen for iphone 5 mobile size //
@media only screen and (max-width: 340px){
  .login .login-container-form{
    padding: 98px 0px;
  }

}
