


.PaymentCheckoutForm {
  padding: 50px 25px;

  .form-payments-card{
    padding: 35px 18px 25px 18px;
    border-radius: 9px;
    margin-bottom: 100px;
  }

    .col-12 {
      padding: 0%;
    }
    .container-expiration {
      padding: 0%;
    }

    .container-cvv {
      padding: 0px 0px 0px 26px;
    }

    .info-user-balance{

      .container-budget{
        font-size: 14px;

        .balance{
          font-weight: 500;
          color: #0e385a;
          span{
            font-weight: 300;
          }
        }

      }

      .alert-payment{
        background: #D4EDDA;
        color: #295725;
        padding: 14px;
        font-size: 15px;
        border-radius: 2px;
        margin-bottom: 23px;
        border: solid #add8b7 1px;
      }
    }


  .amount-container {
    .number {
      font-weight: 600;
      font-size: 20px;
      color: #0e385a;
    }
  }

  .pay-list-container {

    .table {
      width: 100%;
      margin-bottom: 1rem;
      color: #535252;
      // border: solid 1px #dee2e7;
      font-size: 15px;

      thead{
        color:#0f385a;
      }

      td{
        padding: 20px;
        border-top: 0px solid #dee2e6 !important;
      }

      th{
        padding: 20px;
      }
  }
    .container-title span {
      font-size: 12px;
      position: relative;
      bottom: 9px;
      font-weight: 500;
    }
  }


  .container-rnc{
    .form-group{
      display: flex;
      align-items: baseline;
      justify-content: center;
    }
      .text-help{
        font-size: 14px;
      }

      .form-group{
        margin-bottom: 3px;
      }
  }

  .form-payments {
    #icon-input {
      color:#363636;
      margin-bottom: 10px;
      align-items: center;
    }

    .text-pago-en-linea{
      font-weight: 700;
      font-size: 25px;
    }
  }

  padding-bottom: 10px;
  color: #464646;

  .sub-title {
    color: green($color: #000000);
  }
  // .current-amount{
  //     color: #402be2;
  //     font-size: 23px;
  //     font-weight: bold;
  //     margin-bottom: 13px;
  // }

  .container-btn{
    // position: absolute;
    bottom: 0;
    right: 0;
    .submit-pay-button {
      width: 100%;
      background-color: #0f385a;
      border-color: #0f385a;
    }
  }


  .submit-pay-button:hover {
    background-color: #4a9441;
    border-color: #4a9441;
  }

  .submit-pay-button:focus {
    background-color: #4a9441 !important;
    border-color: #4a9441 !important;
  }

  .icons {
    margin-right: 5px;
  }
  .btn-edit-amount {
    margin-left: auto;
    width: 57%;
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .containet-img-carnet{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 40px;
    color: #6b6d6f;
    font-size: 11px;

    img{
      width: 90px;
    }
  }

  .btn-list{
    Button{
    color: #4e4f50;
    background-color: #dededecf;
    border-color: #e4e4e4;
    }
  }

}





@media only screen and (min-width: 767px){

  .form-payments {
    .btn-list{
      display: none !important;
    }

  }


  }




  @media only screen and (max-width: 805px){
    .balance-container{
      margin-bottom: -21px !important;
    }
  }


@media only screen and (max-width: 900px) {
  .PaymentCheckoutForm {


    aside {
      display: none;
    }
    .submit-pay-button {
      width: 100%;
    }

    .pay-list-container .table td{
      padding: 13px;
    }

    .pay-list-container .table th{
      padding: 13px;
    }
  }


  .pay-list-container {
    height: 500px;
  }
}


@media only screen and (max-width: 767px){

 // display none paylist
 .md-hidden{
  display: none;
}



}

#rnc-field {
  //border-right: 0px solid;
}
#input-group-dropdown {
  border: 1px solid #ced4da;
  border-radius: 4px 0px 0px 4px;
  background: #e9ecef;
  &:hover{
    color: black;
  }
  &:active{
    color: black;
  }
}

