

.spinner-component{
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);

.spinner-border{
  color: #838080 !important;
  width: 5rem !important;
  height: 5rem !important;
}

}