

// style componet modal
.modal.show .modal-dialog {
    transform: none;
    padding: 40px 60px;

  .modal-content {
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgb(255, 255, 255) !important;
    border-radius: 10px !important;
    outline: 0;

    .modal-body {
      position: relative;
      flex: 1 1 auto;
      padding: 0;


      .container-alert{
        color: #fff;
        background: linear-gradient(90deg, #E82A3B 50%,  rgb(199, 39, 52) 50%);
        padding: 50px 26px 26px 24px;
        text-align: center;
        border-radius: 10px 10px 80px 80px;
        margin-bottom: 50px;

        h5{
          margin-bottom: -4px;
        }


        .icon-modal{
            font-size: 116px;
            margin-bottom: 21px;
        }
        }
      .container-alert-valid{
        color: #fff;
        background: linear-gradient(90deg, #53A649 50%, #4E9C45 50%);
        padding: 50px 26px 26px 24px;
        text-align: center;
        border-radius: 10px 10px 80px 80px;
        margin-bottom: 50px;

        h5{
          margin-bottom: -4px;
        }


        .icon-modal{
            font-size: 116px;
            margin-bottom: 21px;
        }
        }
      .container-alert-warning{
        color: #fff;
        background: linear-gradient(90deg, #ffc107 50%, #ffc107 50%);
        padding: 50px 26px 26px 24px;
        text-align: center;
        border-radius: 10px 10px 80px 80px;
        margin-bottom: 50px;

        h5{
          margin-bottom: -4px;
        }


        .icon-modal{
            font-size: 116px;
            margin-bottom: 21px;
        }
        }

        .footer-alert{
          p{
            font-size: 14px;
            color: rgb(78, 77, 77);
            padding: 2px 22px;
          }

          .btn-gmail{
            color: #0f385a;
            border-color: #0f385a;
            margin-top: 25px;
            margin-bottom: 32px;
          }

          .btn-gmail:hover{
            color: #fff;
            background-color: #53A649 ;
            border-color:  #53A649 ;
          }
        }

      }
  }
  }


  @media only screen and (max-width: 501px){

    .modal.show .modal-dialog {
     padding: 40px 14px;
    }
  }



