body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.pr-0{
  padding-right: 0px;
}

.pl-0{
  padding-left: 0px;
}
.p-40{
  padding-top: 40px !important;
  padding-bottom: 40px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.pb-0{
  padding-bottom: 0px !important;
}

.btn-primary:disabled{
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}
.btn-primary:hover {
  color: #fff !important;
  background-color: #0069d9 !important;
  border-color: #0062cc !important;
}

.border-2 {
  border-width:2px !important;
}
.bg-red{
  background-color: red !important;
}

.justify-content-evenly{
  justify-content: space-evenly;
}
