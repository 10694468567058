.container-list {

  background-color: #e7eef4;
  padding: 5px;
  border-radius: 6px;
  height: 340px;
  overflow-y: auto;

  .form-check {
    float: left;
    top: 8px;
  }

  .contec-pack-none {
    display: flex;
    align-items: baseline;
    justify-content: center;
    color: #666666;

    span {
      margin-left: 5px;
    }
  }

  .accordion > .card {
    border-radius: 0px;
  }
  .badge-secondary {
    color: #fff;
    background-color: #165283;
    border-radius: 7px;
    padding: 5px 10px;
    font-weight: 600;
    left: 15px;
  }
  .btn:not(:disabled):not(.disabled) {
    color: #666666;
    text-decoration: none;
    display: contents;
    b {
      color: #666666;
      font-weight: 500;
      font-size: 16px;
      margin-left: 7px;
    }

    .container-select {
      position: absolute;
      right: 30px;
    }
  }

  .year-text{
    color: #3f3f3f;
  }

  .pending-small{
    color: #061d2f;
  }

  .container-pay-list {
    .amount-container {
      color: #666666;
      font-weight: 400;

     h5{
        font-weight: 500;
        margin-top: 15px;
        margin-bottom: -0px;

        .icon{
          font-size: 19px;
        }

      }


    }
  }

}
